<template>
  <div>
    <ProgressBar v-if="photoUploading" :uploading="photoUploading" />
    <div class="flex justify-between items-center">
      <Back>
        Back to categories
      </Back>
      <button
        class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white p-1 border border-blue-500 hover:border-transparent rounded-full h-15 w-15 flex items-center justify-center"
        @click="openModal = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
    </div>
    <div class="grid grid-cols-4 gap-10 px-16 py-6">
      <div class="relative" v-for="collection in photoCollections" :key="collection.id">
        <router-link :to="{
          name: 'photos',
          params: { id: collection.id }
        }">
          <div class="relative mb-5">
            <img class="object-cover h-80 w-full" :src="collection.image">
            <div class="text-center left-0 right-0 flex justify-around items-center">
              <h2 class="mt-2 text-3xl text-black capitalize">
                {{collection.name}}
                <br>
                <span class="text-sm">
                  {{collection.location}}
                </span>
              </h2>
            </div>
          </div>
          <button class="w-10 h-10 bg-red-600 text-white rounded-md absolute -top-4 -right-4" @click.prevent='deleteCollection(collection)'>X</button>
        </router-link>
      </div>
    </div>

    <Modal :showing="openModal" @close="openModal = false">
      <h2>Create a new Photo collection</h2>

      <form class="flex flex-col">
        <input
          v-model="collectionName"
          type="text"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm rounded-md border-2 border-black"
          placeholder="Photo collection name"
        />
        <br />
        <input
          v-model="location"
          type="text"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm rounded-md border-2 border-black"
          placeholder="location"
        />
        <br />
        <div>
          <label
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 rounded"
          >
            <input
              type="file"
              @change="hendleImageChange"
              class="opacity-0 h-0 w-0"
            />
            <span>Upload Photos</span>
          </label>

          <div class="flex">
            <!-- <div class="py-2 px-4" v-for="(image, key) in prevImages" :key="key"> -->
            <img
              class="w-10"
              :src="collectionPrevImage.path"
              :alt="collectionPrevImage.name"
            />
            <!-- </div> -->
          </div>
        </div>

        <button
          @click.prevent="addCollection"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 rounded"
        >
          Create new collection
        </button>
      </form>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Back from '@/components/GoBack'
import ProgressBar from "@/components/ProgressBar";
import Modal from "@/components/Modal";

export default {
  name: "photos",
  components: {
    Modal,
    ProgressBar,
    Back
  },
  data() {
    return {
      categoryId: this.$route.params.categoryId,
      file: null,
      openModal: false,
      collectionName: "",
      location: "",
      collectionPrevImage: {},
      imageTypes: ["image/png", "image/jpeg", "image/jpg"],
    };
  },
  computed: {
    ...mapGetters(["photoCollections", "photoUploading"]),
  },
  methods: {
    hendleImageChange(e) {
      this.file = e.target.files[0];
      if (this.file && this.imageTypes.includes(this.file.type)) {
        this.collectionPrevImage = {
          name: this.file.name,
          path: URL.createObjectURL(this.file),
        };
      }
    },
    addCollection() {
      const data = {
        name: this.collectionName,
        categoryId: this.categoryId,
        location: this.location,
        image: this.file,
      };

      this.$store.dispatch("addPhotoCollection", data);

      this.file = null;
      this.collectionPrevImage = {};
      this.collectionName = "";
      this.location = "";

      this.openModal = false;
    },
    deleteCollection(collection) {
      this.$store.dispatch("deleteCollection", collection)
    }
  },
  mounted() {
    // this.$store.dispatch("getCategories");
    this.$store.dispatch("getCollections", this.categoryId);
  },
};
</script>

<style></style>
